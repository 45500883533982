@use "../../foundation/system" as system;

.p-privacy {
  margin: 0 auto;
  @include system.content-maxwidth();
  padding: 0;

  &__title {
    @include system.text-bold(22px);
    margin-bottom: 30px;
  }

  &__description {
    @include system.text-base(18px);
    margin-bottom: 30px;
  }

  .p-privacy__item {
    padding-left: 20px;
    margin-bottom: 30px;
    .p-privacy-item__title {
      @include system.text-bold(18px);
      margin-bottom: 30px;
    }
    .p-privacy-item__description {
      @include system.text-base(18px);
      margin-bottom: 30px;
    }
    .p-privacy-item__content {
      padding-left: 20px;
      @include system.text-base(18px);
      list-style: decimal;
      list-style-position: inside;
    }
  }

  @media (max-width: 1199.98px) {
    padding: 0 15px;
  }

  @media (max-width: 575.98px) {
    &__title {
      @include system.text-bold(18px);
      margin-bottom: 20px;
    }

    &__description {
      @include system.text-base(14px);
    }

    .p-privacy__item {
      padding-left: 15px;
      .p-privacy-item__title {
        @include system.text-bold(14px);
        margin-bottom: 20px;
      }
      .p-privacy-item__description {
        @include system.text-base(14px);
        margin-bottom: 20px;
      }
      .p-privacy-item__content {
        padding-left: 15px;
        @include system.text-base(14px);
      }
    }
  }
}
