@use '../../foundation/system' as system;

.p-searchMedicine {
  &__input {
    margin: 0 0 100px;

    &__container {
      margin: 130px 0 38px;
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 25px 0 15px;
      @include system.formBackground();

      form {
        width: 100%;
        max-width: 85%;
        margin: 0 auto;
        padding: 0 0 25px;
    
        > label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 10px;
          @include system.text-bold(17px);
          color: #6c6c6c;
        }
    
        select {
          border: 1px solid #a5a5a5;
          @include system.text-base(17px);
        }
    
        > div:nth-of-type(4),
          div:nth-of-type(6),
          div:nth-of-type(8),
          div:nth-of-type(10) {
          margin: 0;
        }

        .c-selectBox {
          margin: 0 0 50px;
        }

        .l-formInput__radio {
          margin: 30px 0 50px;
        }
      }
    }

    &__checkbox {
      > div {
        display: flex;
        margin: 0 0 10px;

        label {
          margin: 20px 0 0;
        }

        label + label {
          margin: 20px 0 0 40px;
        }
      }
    }

    .l-formInput__date {
      div {
        position: relative;
        display: flex;
        justify-content: space-between;
  
        input {
          width: calc((100% - 33px) / 2);
        }
  
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%);
          content: '〜';
        }
      }
    }
  }

  &__result {
    @include system.searchResultTitle()
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 85px auto 230px;
    padding: 0 60px 10px;

    li {
      width: 100%;
      max-width: calc((100% - 60px) / 2);
      margin: 0 0 80px;
      background-color: system.$c-gray;

      a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 20px 50px;

        &::after {
          position: absolute;
          bottom: 16px;
          right: 10px;
          width: 180px;
          height: 7px;
          background: no-repeat url(/assets/img/listArrow.svg);
          background-size: 100%;
          content: '';
        }
      }

      p.type {
        margin: 0 0 13px;
        @include system.text-bold(20px);
        color: system.$c-blue;
      }

      p:nth-of-type(2) {
        margin: 10px 0 5px;
      }

      span {
        display: inline;
        padding: 0 8px 0 0;

        & + span {
          padding: 0 8px;
          border-left: 1px solid system.$c-blue;
        }
      }
    }
  }

  &__detail {
    dd span {
      @include system.text-base();
      color: system.$c-red;

      &.bold {
        @include system.text-bold();
        color: system.$c-black;
      }
    }

    &__detail {
      > div {
        margin: 20px 0 0;
      }

      dt {
        @include system.text-bold();
      }
    }

    &__title.red {
      background-color: #FFE7E7;
    }

    &__text.red {
      color: system.$c-red;
    }
  }

  @include system.breakpoint(tablet) {
    &__input {
      &__container {
        margin: 55px auto 38px;
      }
      
      .c-actionButton {
        justify-content: flex-end;

        button {
          width: 270px;
          border-radius: 10px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      form { 
        .c-selectBox {
          margin: 0 0 30px;
        }

        .l-formInput__radio {
          margin: 10px 0 30px;
        }
      }
    }

    &__list {
      padding: 0;
      margin: 50px auto 110px;

      li {
        max-width: unset;
        margin: 0 0 33px;

        a {
          padding: 20px 36px 45px;
        }
      }
    }

    &__detail {
      margin: 0 0 90px;
      .c-linkText.bold {
        text-align: center;
      }
    }
  }

  @include system.breakpoint(sp) {
    &__input {
      form {
        > lable {
          font-size: 11px;
        }

        select {
          font-size: 10px;
        }

        .c-selectBox {
          margin: 0 0 30px;
        }
      }

      &__checkbox {
        label {
          margin: 10px 0 0;

          & + label {
            margin: 10px 0 0 25px;
          }
        }
      }
    }

    &__list {
      p {
        font-size: 12px;
      }
    }

    &__detail {
      dd span {
        font-size: 13px;

        &.bold {
          font-size: 13px;
        }
      }

      &__detail {
        dt {
          font-size: 13px;
        }
      }
    }
  }
}