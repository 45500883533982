@use '../../foundation/system' as system;

.c-sectionTitle {
  position: relative;
  margin: 0 0 80px;
  padding: 0 0 30px;
  text-align: center;

  span {
    @include system.text-bold(24px);

    &::before {
      margin: 0 26px 0 0;
      @include system.fontAwesome('\f129');
      color: system.$c-brown;
      font-size: 30px;
    }
  }

  &.userInfo {
    span::before {
      content: ('\f4fe');
    }
  }

  @mixin border() {
    position: absolute;
    width: 375px;
    height: 1px;
    background-color: system.$c-borderColorGray;
    content: '';

    @include system.breakpoint(tablet) {
      width: 200px;
    }
  }

  &::before {
    @include border();
    bottom: 10px;
    left: 32%;
  }

  &::after {
    @include border();
    bottom: 0;
    right: 32%;
  }

  @include system.breakpoint(tablet) {
    margin: 0 0 40px;
    padding: 0 0 25px 25px;
    text-align: start;

    span {
      &::before {
        margin: 0 8px 0 0;
        font-size: 24px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: unset;
      left: 55px;
    }
  }

  @include system.breakpoint(sp) {
    padding: 0 0 20px 25px;

    span {
      font-size: 14px;
    }
  }
}