@use '../foundation/system' as system;

.l-confirm {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100vh;
  padding: 40px 0 0;
  background: rgba(241, 255, 244, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  overflow-y: scroll;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  h2 {
    @include system.text-bold(32px);
    color: system.$c-black;
    text-align: center;
    border: unset;
  }

  &__closeBtn {
    position: absolute;
    top: 32px;
    left: 60px;
    display: block;
    display: flex;
    width: 50px;
    height: 50px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include system.fontAwesome("\f057");
      font-size: 40px;
      font-weight: 400;
    }
  }

  &__table {
    width: calc(100% - 120px);
    margin: 30px auto;
    padding: 60px 10px;
    background-color: system.$c-white;

    dl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;

      > div {
        padding: 0 0 10px;
        border-bottom: 1px solid #b8b8b8;
      }

      > div + div {
        margin: 40px 0 0;
      }
    }

    dt {
      @include system.text-bold(18px);
    }

    dd {
      margin: 10px 0 0;
      @include system.text-base(14px);
    }

    button {
      width: 250px;
      margin: 62px auto 0;
    }
  }

  @include system.breakpoint(tablet) {
    padding: 30px 0 0;

    h2 {
      font-size: 24px;
    }

    &__closeBtn {
      top: 25px;
      left: 20px;
      width: 40px;
      height: 40px;

      &::before {
        font-size: 35px;
      }
    }

    &__table {
      width: calc(100% - 30px);
      padding: 30px 10px;

      button {
        margin: 50px auto 0;
      }
    }
  }

  @include system.breakpoint(sp) {
    padding: 25px 0 0;

    h2 {
      font-size: 18px;
    }

    &__closeBtn {
      top: 15px;
      left: 10px;
    }

  }
}