@use '../../foundation/system' as system;

.c-display {
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 160px auto 0;
  padding: 0 60px;

  div {
    margin: 0 0 0 10px;

    select {
      width: 96px;
      margin: 0;
      padding: 8px 15px;
      box-shadow: unset;
      border-radius: 5px;
      border: 1px solid #a5a5a5;
    }

    &::after {
      right: 10px;
      font-size: 15px;
    }
  }
}