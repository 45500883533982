@use '../../foundation/system' as system;

.p-patient {
  &__table {
    display: block;
    width: calc(100% - 50px);
    max-width: 1250px;
    margin: 0 auto 120px;
    padding: 50px 60px;
    background-color: system.$c-lightGreen;
    border-collapse: collapse;
    border-spacing:0px;

    thead,
    tbody {
      display: block;
      width: 100%;
      background-color: system.$c-white;
    }

    tr {
      display: flex;
    }

    th {
      width: calc(100% / 4);
      padding: 15px 0;
      text-align: center;
      border-collapse: collapse;
      border: system.$border-gray;
      @include system.text-bold(17px);

      & + th {
        border-left: unset;
      }

      &:last-child {
        color: system.$c-red;
      }
    }

    td {
      width: calc(100% / 4);
      padding: 15px 0;
      text-align: center;
      border-collapse: collapse;
      border: system.$border-gray;
      border-top: unset;
      @include system.text-base(16px);

      & + td {
        border-left: unset;
      }

      &:last-child {
        @include system.text-bold(16px);
        color: system.$c-red;
      }
    }
  }

  &__list {
    margin: 210px 0 0;

    &.margin {
      margin: 100px 0 0;
    }

    > div:nth-of-type(1),
    > div:nth-of-type(2) {
      padding: 0 60px;
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      @include system.text-bold(24px);

      &::before {
        margin: 0 15px 0 0;
        @include system.fontAwesome('\f00c');
        color: system.$c-brown;
        font-size: 30px;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1440px;
      margin: 85px auto 0;
      padding: 0 60px 10px;

      & + ul {
        margin: 0 auto;
      }
    }
  }

  &__pagination {
    display: flex;
    margin-bottom: 50px;
    ul {
      margin: 0 auto;
    }
  }

  &__attention {
    width: 100%;
    max-width: 680px;
    margin: 40px auto 130px;
    padding: 40px 60px;
    border: 1px dashed system.$c-red;
    @include system.text-base(17px);
    color: system.$c-red;
    line-height: 1.5;
  }

  &__edit {
    width: 400px;
    max-width: 1080px;
    max-height: 60px;
    margin: 150px auto 160px;
    padding: 0 20px;
    border: system.$border-gray;
    background-color: system.$c-gray;
    transition: .3s;
    overflow: hidden;

    > div {
      position: relative;
      padding: 15px 0;
      @include system.text-bold(24px);
      color: system.$c-blue;
      text-align: center;
      cursor: pointer;

      &::before {
        content: unset;
        transition-delay: 0s;
      }

      &::after {
        position: absolute;
        top: 40%;
        right: 30px;
        transform: translate(0, -50%);
        @include system.fontAwesome('\f0dd');
        transition-delay: .3s;
      }
    }

    textarea {
      resize: none;
      width: 100%;
      height: 300px;
      padding: 18px 30px;
      margin: 0 0 30px;
      background-color: system.$c-white;
      border: system.$border-gray;
      opacity: 0;
      transition: .2s;

      &::placeholder {
        color: #929292;
      }
    }

    &.open {
      width: calc(100% - 30px);
      max-height: 500px;
      padding: 0 20px 30px;
      transition: .5s;

      > div {
        &::after {
          opacity: 0;
          transition-delay: 0s;
        }

        &::before {
          position: absolute;
          top: 55%;
          left: 0px;
          transform: translate(0, -50%);
          @include system.fontAwesome('\f057');
          font-size: 30px;
          font-weight: 400;
          transition-delay: .5s;
        }
      }

      textarea {
        opacity: 1;
        transition: .3s;
        transition-delay: .32s;
      }
    }
  }

  @include system.breakpoint(tablet) {
    &__table {
      display: flex;
      padding: 30px 20px;

      tr {
        flex-direction: column;
      }

      th {
        width: 100%;
        padding: 15px 20px;
        font-size: 16px;
        text-align: left;

        & + th {
          border-left: system.$border-gray;
          border-top: unset;
        }
      }

      td {
        width: 100%;
        border: system.$border-gray;
        border-left: unset;
        font-size: 16px;

        & + td {
          border-top: unset;
        }
      }
    }

    &__list {
      margin: 120px 0 180px;

      &.margin {
        margin: 80px 0 0;

        ul {
          margin: 50px auto 45px;
        }
      }

      > div:nth-of-type(1),
      > div:nth-of-type(2) {
        padding: 0 20px;
      }

      .c-display {
        margin: 60px 0 0;
      }

      ul {
        margin: 50px auto 165px;
        padding: 0;
      }
    }

    &__attention {
      width: calc(100% - 70px);
      margin: 20px auto 45px;
    }

    &__edit {
      margin: 75px auto 110px;
      max-height: 50px;

      textarea {
        height: 200px;
        margin: 0 0 20px;
        padding: 13px 17px;
      }

      &.open {
        max-height: 330px;
        padding: 0 20px 20px;
      }
    }

    &__detail {
      .c-actionButton {
        margin: 0 0 100px;
      }
    }

    @include system.breakpoint(sp) {
      &__table {
        th,
        td {
          font-size: 12px;
        }
      }

      &__attention {
        padding: 20px;
      }

      &__edit {
        width: calc(100% - 30px);

        > div {
          font-size: 14px;

          &::after {
            right: 0;
          }
        }

        &.open {
          > div::before {
            top: 50%;
            font-size: 25px;
          }
        }
      }
    }
  }
}