@use '../../foundation/system' as system;

.c-linkText {
  @include system.text-base(16px);
  color: system.$c-blue;
  text-align: right;

  a {
    &::before {
      display: inline-block;
      margin: 0 5px 0 0;
      content: '≫';
    }
  }

  &.underline {
    margin: 0 10px;

    a {
      border-bottom: 1px dashed system.$c-blue;
    }
  }

  &.bold {
    margin: 140px 0 0;
    padding: 0 0 10px 0;
    @include system.text-bold(24px);
    text-align: center;

    button,
    a {
      display: inline-block;
      padding: 0 0 10px;
      border-bottom: 1px dashed system.$c-blue;

      &::before {
        content: unset
      }
    }
  }

  @include system.breakpoint(tablet) {
    font-size: 14px;

    &.bold {
      margin: 25px 0 0;
      padding: 0 10px 3px;
      text-align: right;
      font-size: 16px;

      button,
      a {
        padding: 0 0 3px;
      }
    }
  }

  @include system.breakpoint(sp) {
    font-size: 10px;

    &.bold {
      font-size: 14px;
    }
  }
}