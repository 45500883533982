@use '../../foundation/system' as system;

.c-input {
  margin: 18px 0 0;
  padding: 20px 18px;
  background-color: system.$c-white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  @include system.text-base(16px);

  &::placeholder {
    @include system.text-base(16px);
    color: #929292;
  }

  &.error {
    border: 1px solid system.$c-red;
  }

  &__border {
    margin: 18px 0 0;
    padding: 20px 18px;
    background-color: system.$c-white;
    border: system.$border-gray;
    @include system.text-base(16px);

    &.error {
      border: 1px solid system.$c-red;
    }
  }

  @include system.breakpoint(tablet) {
    margin: 10px 0 0;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }

  @include system.breakpoint(sp) {
    margin: 10px 0 0;
    padding: 10px 12px;
    font-size: 10px;

    &::placeholder {
      font-size: 10px;
    }

    &__border {
      margin: 10px 0 0;
      padding: 10px 12px;
      font-size: 10px;
    }
  }
}