@use '../../foundation/system' as system;

.c-selectBox {
  margin: 14px 0 0;

  &__container {
    position: relative;
    background-color: system.$c-white;

    select {
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      padding: 20px 18px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
      @include system.text-base(16px);
      cursor: pointer;

      &.error {
        border: 1px solid system.$c-red;
      }

      &.selected {
        color: system.$c-black;
      }
    }

    &::after {
      position: absolute;
      top: 45%;
      right: 35px;
      z-index: 1;
      transform: translate(0, -50%);
      @include system.fontAwesome("\f0dd");
    }
  }

  &__border {
    margin: 18px 0 0;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 20px;
      @include system.text-bold(17px);
      color: #6c6c6c;
    }

    select {
      box-shadow: unset;
      border: system.$border-gray;
    }
  }

  //   &.address {
  //     select {
  //       border: 1px solid #a5a5a5;
  //       box-shadow: unset;
  //       color: system.$c-black;
  //     }

  //     select.error {
  //       border: 1px solid system.$c-red;
  //     }
  //   }

  //   &.year {
  //     width: 40%;

  //     select {
  //       border: 1px solid #a5a5a5;
  //       box-shadow: unset;
  //       color: system.$c-black;
  //     }

  //     select.error {
  //       border: 1px solid system.$c-red;
  //     }
  //   }

  //   &.date {
  //     width: 25%;

  //     select {
  //       border: 1px solid #a5a5a5;
  //       box-shadow: unset;
  //       color: system.$c-black;
  //     }

  //     select.error {
  //       border: 1px solid system.$c-red;
  //     }
  //   }
  // }

  // &.black {
  //   margin: 15px 0 0;
  //   padding: 0;
  //   border-bottom: unset;

  //   select {
  //     padding: 12.25px 20px;
  //     border: 1px solid #a5a5a5;
  //     box-shadow: unset;
  //     color: system.$c-black;

  //     &.error {
  //       border: 1px solid system.$c-red;
  //     }
  //   }

  //   .c-selectBox__container::after {
  //     right: 25px;
  //   }
  // }

  // &.border {
  //   border-bottom: unset;

  //   select {
  //     box-shadow: unset;
  //   }
  // }

  // &__orig {
  //   display: flex;
  //   justify-content: space-between;
  //   margin: 12px 0 0;
  // }

  @include system.breakpoint(tablet) {
    margin: 10px 0 0;

    &__container {
      select {
        font-size: 14px;
      }
    }

    &__border{
      margin: 30px 0 0; 
    }
  }

  @include system.breakpoint(sp) {
    &__container {
      select {
        padding: 10px 20px;
        font-size: 10px;
      }

      &::after {
        right: 10px;
      }
    }
  }
}