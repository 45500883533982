@use '../../foundation/system' as system;

.c-searchPatientForm {
  &__form {
    position: relative;
    width: 100%;
    padding: 50px 24px 40px 26px;
    margin: 0 0 50px;
    @include system.formBackground();
    
    button {
      margin: 70px auto 0;
    }
    
    &__select {
      margin: 0 0 50px;
    }
  }

  &__date {
    padding: 0;
    border-bottom: unset;

    > div {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 0 0 10px;

      input {
        position: relative;
        width: 100%;
        max-width: calc((100% - 60px) / 2);

        &::after {
          position: absolute;
          bottom: -10px;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 1px;
          background-color: system.$c-borderColorGray;
          content: '';
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '〜';
      }
    }
  }
}