@use '../../foundation/system' as system;

.p-home {
  &__message {
    margin: 105px 0 0;
    padding: 0 60px;

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1320px;
      margin: 0 auto;
      padding: 0 0 10px;
    }
  }

  &__news {
    margin: 105px 0 0;
    padding: 0 60px;
  }

  @include system.breakpoint(tablet) {
    &__message {
      margin: 80px 0 0;
      padding: 0;
    }

    &__news {
      margin: 80px 0 0;
      padding: 0;
    }
  }
}