@use '../foundation/system' as system;

.l-formInput {
  width: 100%;
  max-width: 900px;
  margin: 10px auto 0;

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px;
    padding: 0 0 10px;
    border-bottom: 1px solid #b8b8b8;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include system.text-bold(17px);
      color: #6c6c6c;
    }

    &.border {
      border-bottom: unset;
    }

    &.outline {
      border-bottom: unset;

      input {
        border: 1px solid #A5A5A5;
        box-shadow: unset;
      }
    }
  }

  &__required {
    width: 80px;
    background-color: system.$c-red;
    @include system.text-bold(16px);
    color: system.$c-white;
    line-height: 40px;
    text-align: center;
  }

  &__any {
    width: 80px;
    background-color: system.$c-dark;
    @include system.text-bold(16px);
    color: system.$c-white;
    line-height: 40px;
    text-align: center;
  }

  &__errorMessage {
    position: relative;
    display: inline-block;
    margin: 5px 0 0;
    @include system.text-bold(16px);
    color: system.$c-red;

    &::before {
      margin: 0 2px 0 0;
      @include system.fontAwesome("\f071");
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    margin: 0 0 50px;
    padding: 0 0 10px;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include system.text-bold(17px);
      color: #6c6c6c;
    }

    input {
      padding: 20px 28px 20px 18px;
    }
  }

  &__radio {
    margin: 0 0 50px;
    
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 10px;
      @include system.text-bold(17px);
      color: #6c6c6c;
    }

    label {
      display: inline-block;
      height: 26px;
      cursor: pointer;
    }

    input {
      display: none;
    }

    &__text {
      position: relative;
      align-items: center;
      display: inline-block;
      line-height: 26px;
      padding: 0 0 0 35px;
      @include system.text-base(17px);

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 26px;
        height: 26px;
        background-color: system.$c-white;
        border: 1px solid #989898;
        border-radius: 50%;
        content: "";
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translate(0, -50%);
        width: 18px;
        height: 18px;
        background-color: system.$c-blue;
        border-radius: 50%;
        content: '';
        opacity: 0;
      }
    }

    input:checked + &__text::after {
      opacity: 1;
    }

    label + label {
      margin: 0 0 0 30px;
    }
  }

  &__checkbox {
    margin: 0 0 50px;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include system.text-bold(17px);
      color: #6c6c6c;
    }

    label {
      display: flex;
      margin: 30px 0 0;
    }

    input {
      display: none;
    }

    input:checked + &__text::after {
      opacity: 1;
    }

    &__text {
      position: relative;
      padding: 0 0 0 40px;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 30px;
        height: 30px;
        border: 1px solid #a5a5a5;
        background-color: system.$c-white;
        border-radius: 5px;
        content: "";
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translate(0, -50%);
        width: 22px;
        height: 22px;
        background-color: system.$c-blue;
        border-radius: 5px;
        content: '';
        opacity: 0;
      }
    }
  }

  &__address {
    margin: 12px 0 0;
    
    &.number {
      display: flex;
      align-items: center;
      
      input {
        margin: 0 0 0 5px;
      }
    }

    &.prefecture {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 12px 0 0;

      > div {
        width: 49%;
      }

      select.error {
        border: 1px solid system.$c-red;
      }

      input {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__insuranceNum {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 0;

    input {
      width: 70%;
      margin: 0;
    }

    > div {
      width: 25%;
    }

  }

  @include system.breakpoint(tablet) {
    &__form {
      margin: 0 0 10px;

      label {
        font-size: 15px;
      }
    }

    &__any,
    &__required {
      width: 60px;
      font-size: 14px;
      line-height: 30px;
    }

    &__date {
      margin: 0 0 30px;

      label {
        font-size: 15px;
      }
    }

    &__radio {
      margin: 0 0 30px;

      p {
        font-size: 15px;
      }
    }

    &__checkbox {
      margin: 0 0 30px;
    }
  }

  @include system.breakpoint(sp) {
    &__form {

      label {
        font-size: 11px;
      }
    }

    &__any,
    &__required {
      width: 40px;
      font-size: 10px;
      line-height: 18px;
    }

    &__date {
      margin: 0 0 20px;
      
      label {
        font-size: 12px;
      }

      input {
        padding: 10px 12px;
      }
    }

    &__radio {
      margin: 0 0 20px;

      p {
        font-size: 12px;
      }

      &__text {
        font-size: 10px;
      }
    }

    &__checkbox {
      margin: 0 0 30px;

      &__text {
        font-size: 10px;
      }
    }
  }
}