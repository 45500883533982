@use '../foundation/system' as system;

.l-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 40px 0 20px;

  @media screen and (max-width: 1279px) {
    padding: 0 20px;
  }

  &__logo {
    display: flex;
    align-items: center;
    margin: 0 30px 0 0;

    h1 {
      max-width: 285px;
      min-width: 210px;
      margin: 0 10px 0 0;

      @media screen and (max-width: 1279px) {
        min-width: 180px;
      }
    }

    img {
      width: 100%;
    }

    span {
      font-size: 14px;

      @media screen and (max-width: 1279px) {
        font-size: 12px;
      }
    }
  }

  &__nav {
    display: flex;
    width: 100%;
    max-width: 883px;
    @media screen and (max-width: 1279px) {
      max-width: 730px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    li + li {
      margin: 0 0 0 10px;
    }

    a {
      display: block;
      @include system.text-bold(16px);

      @media screen and (max-width: 1279px) {
        @include system.text-bold(14px);
      }
    }

    &::before {
      margin: 0 30px 0 0;
      @include system.fontAwesome('\f141');
      color: system.$c-blue;

      @media screen and (max-width: 1279px) {
        margin: 0 10px 0 0;
      }
    }
  }

  @include system.breakpoint(tablet) {
    height: 60px;
    padding: 0 10px;

    &__logo {
      h1 {
        width: 250px;
      }
    }

    &__nav {
      display: none;
    }
  }

  @include system.breakpoint(sp) {
    height: 40px;

    &__logo {
      h1 {
        width: 168px;
      }
      
      span {
        font-size: 10px;
      }
    }
  }
}