@use '../../foundation/system' as system;

.c-mainButton {
  display: block;
  min-width: 400px;
  height: 55px;
  margin: 0 auto;
  @include system.title-base();
  color: system.$c-white;
  text-align: center;

  &.blue {
    border-radius: 30px;
    background-color: system.$c-blue;

    &.min {
      font-size: 14px;
    }
  }

  &.green {
    background-color: #3BA12B;
    font-size: 20px;
  }

  &.white {
    background-color: system.$c-white;
    color: #3457D6;
  }

  &.black {
    border-radius: 20px;
    background-color: system.$c-black;
  }

  &.message {
    position: relative;
    border: 1px solid #A5A5A5;  
    border-radius: 30px;
    color: system.$c-black;

    &::after {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
      z-index: 1;
      @include system.fontAwesome('\f055');
      font-size: 30px;
    }
  }

  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 90px auto 100px;
    border-radius: 20px;
    background-color: system.$c-blue;
    font-size: 10px;
  }

  @include system.breakpoint(tablet) {
    min-width: 300px;
    height: 35px;
    font-size: 14px;

    &.blue {
      &.min {
        font-size: 12px;
      }
    }

    &.message::after {
      font-size: 20px;
    }
  }

  @include system.breakpoint(sp) {
    font-size: 10px;

    &.blue {
      &.min {
        font-size: 10px;
      }
    }

    &.message::after {
      right: 10px;
    }
  }
}