@use '../../foundation/system' as system;

.c-linkButton {
  width: 550px;
  margin: 100px auto 0;

  a {
    display: block;
    width: 100%;
    @include system.text-bold(24px);
    color: system.$c-blue;
    line-height: 73px;
    text-align: center;
    border: 3px solid system.$c-blue;
    border-radius: 10px;

    &::after {
      margin: 0 0 0 20px;
      @include system.fontAwesome('\f35a');
      font-weight: 400;
    }
  }

  @include system.breakpoint(tablet) {
    width: 100%;
    max-width: 330px;
    margin: 50px 0 0;
    padding: 0 10px 0 0;

    a {
      border-left: unset;
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      line-height: 53px;
    }
  }
}