@use "../../foundation/system" as system;

.c-messageList {
  width: 100%;
  max-width: calc((100% - 60px) / 2);
  height: 210px;
  margin: 0 0 80px;
  background-color: system.$c-gray;

  &.reservation {
    height: 310px;
  }

  &.patient {
    height: 150px;
  }

  @mixin label($height, $color) {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: $height;
    background-color: $color;
    @include system.text-bold(14px);
    color: system.$c-white;

    @include system.breakpoint(tablet) {
      transform: translate(0, -40%);
      width: 60px;
      font-size: 12px;
    }

    @include system.breakpoint(sp) {
      font-size: 10px;
    }
  }

  &:last-child {
    margin: 0;
  }

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px;

    &.label::before {
      @include label(60px, system.$c-dark);
      content: "再訪";
    }

    &.useLabel::before {
      @include label(60px, system.$c-dark);
      content: "連携済み";
    }

    &::after {
      position: absolute;
      bottom: 20px;
      right: 10px;
      z-index: 1;
      width: 290px;
      height: 9px;
      background: no-repeat url(/assets/img/listArrow.svg);
      background-size: 100%;
      content: "";
    }
  }

  &.red {
    background-color: #ffe7e7;
    box-shadow: 0px 4px 4px rgba(177, 124, 124, 0.25);

    .label::before {
      @include label(75px, system.$c-red);
      content: "初来訪";
    }

    .useLabel::before {
      @include label(75px, system.$c-red);
      content: "未連携";
    }
  }

  &__time {
    display: flex;
    align-items: center;
    @include system.text-base(20px);

    &::before {
      margin: 0 8px 0 0;
      @include system.fontAwesome("\f017");
      font-weight: 400;
    }

    span {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0 0;
  }

  &__name {
    @include system.title-base();

    span {
      color: system.$c-blue;
    }

    span:nth-of-type(2) {
      @include system.text-base(20px);
      color: system.$c-blue;
    }
  }

  &__number {
    @include system.text-base(14px);
  }

  &__medical {
    margin: 25px 0 0;
  }

  &__hospital {
    margin: 0 5px 0 0;
    padding: 0 5px 0 0;
    border-right: 1px solid system.$c-black;
    @include system.text-bold();
  }

  &__medicine {
    margin: 15px 0 0;
  }

  &.history {
    .c-messageList__hospital {
      @include system.text-bold(20px);
      color: system.$c-blue;
    }
  }

  @mixin messages($icon) {
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    @include system.text-bold(24px);
    color: system.$c-red;

    &::before {
      margin: 0 10px 0 0;
      @include system.fontAwesome($icon);
      font-size: 30px;
    }

    @include system.breakpoint(tablet) {
      margin: 10px 0 0;
      font-size: 14px;

      &::before {
        margin: 0 12px 0 0;
        font-size: 24px;
      }
    }
  }

  &__message {
    @include messages("\f0e0");
  }

  &__remort {
    @include messages("\f500");
  }

  @include system.breakpoint(tablet) {
    max-width: 100%;
    height: auto;
    margin: 0 0 45px;

    &.reservation {
      height: auto;
    }

    a {
      padding: 20px 20px 30px;

      &.useLabel::before &.label::before {
        @include label(40px, #7d7d7d);
      }

      &::after {
        bottom: 10px;
        right: 13px;
        width: 180px;
        height: 6px;
      }
    }

    &.red .label::before,
    &.red .useLabel::before {
      @include label(50px, system.$c-red);
    }

    &__title {
      flex-direction: column;
      align-items: flex-start;
      margin: 15px 0 0;
    }

    &__number {
      margin: 8px 0 0;
    }

    &__medical {
      margin: 12px 0 0;
    }

    &__medicine {
      margin: 5px 0 0;
    }
  }

  @include system.breakpoint(sp) {
    &__number {
      font-size: 12px;
    }

    &__doctor {
      font-size: 12px;
    }

    &__medicine {
      font-size: 12px;
    }
  }
}
