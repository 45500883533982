@use '../foundation/system' as system;

.l-table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 220px);
  max-width: 1220px;
  margin: 125px auto 0;

  &.flex {
    flex-wrap: nowrap;
    width: calc(100% - 40px);
    max-width: 1360px;
    margin: 200px auto 0;

    > div {
      width: calc((100% - 30px) / 3);
    }

    dt {
      width: 100%;
      max-width: unset;
    }
  }

  > div {
    position: relative;
    width: calc((100% - 30px) / 2);
    max-width: 550px;
    margin: 0 0 70px;
    padding: 0 0 30px;

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 90%;
      height: 1px;
      background-color: system.$c-borderColorGray;
      content: '';
    }

    &:last-child::after,
    &.last::after {
      content: unset;
    }

    > dt {
      width: 80%;
      max-width: 410px;
      padding: 0 25px;
      background-color: #E2E2E2;
      border-radius: 40px;
      @include system.text-bold();
      line-height: 45px;
    }

    > dd {
      margin: 30px 0 0;
      padding: 0 0 0 20%;
      overflow-wrap: break-word;
      line-height: 1.5;
      white-space: pre-wrap;
  
      & + dd {
        margin: 10px 0 0;
      }
  
      &.insurance span {
        display: block;
  
        & + span {
          margin: 5px 0 0;
        }
      }
  
      .space {
        display: inline-block;
        margin: 0 0 0 5px;
      }

      div + div {
        margin: 20px 0 0;
      }
    }

    &.width {
      width: 100%;
      max-width: unset;

      &::after {
        width: 95%;
      }

      > dd {
        padding: 0 0 0 10%;
      }

      dl {
        dd {
          padding: 0;
          white-space: pre-wrap;
        }
      }
    }
  }

  a {
    color: system.$c-blue;

    &::before {
      display: inline-block;
      margin: 0 5px 0 0;
      content: '≫';
    }
  }

  &__limit {
    display: inline-block;
    margin: 0 0 0 5px;
    @include system.text-bold(15px);
    color: system.$c-red;
  }

  &__space {
    display: inline-block;
    margin: 0 0 0 5px;
  }

  &__img {
    width: 100%;
    background-color: #C4C4C4;
    padding: 56.25% 0 0;
  }

  @include system.breakpoint(tablet) {
    flex-direction: column;
    width: 100%;
    margin: 40px 0 0;

    &.flex {
      width: 100%;
      margin: 80px 0 0;

      > div {
        width: 100%;
      }

      dt {
        width: 90%;
      }
    }

    > div {
      width: 100%;
      max-width: unset;
      margin: 0 0 40px;
      padding: 0 0 20px;

      &::after {
        right: unset;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
      }

      &.last::after {
        content: '';
      }

      > dt {
        width: 90%;
        padding: 0 0 0 30px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        line-height: 30px;
      }
  
      > dd {
        margin: 20px 0 0;
        padding: 0 10% 0 20%;
      }

      &.width {
        &::after {
          width: 80%;
        }

        > dd {
          padding: 0 10% 0 20%;
        }
      }
    }

    &__limit {
      font-size: 14px;
    }
  }

  @include system.breakpoint(sp) {
    dt {
      font-size: 14px;
    }

    dd {
      font-size: 13px;
    }

    &__limit {
      display: block;
      margin: 5px 0 0;
      font-size: 12px;
    }
  }
}