@use '../foundation/system' as system;

.l-top {
  margin: 0 0 110px;

  &__top {
    width: 100%;
    padding: 20px 0 0;
    background-color: system.$c-gray;
  }

  &__img {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__user {
    width: 100%;
    height: 60px;

    @mixin border() {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      width: 200px;
      height: 1px;
      background-color: system.$c-borderColorGray;
      content: '';

      @include system.breakpoint(tablet) {
        width: 50px;
      }

      @include system.breakpoint(sp) {
        top: 32px;
        transform: translate(0);
      }
    }

    p {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &::before {
        @include border();
        left: 20px;
      }
  
      &::after {
        @include border();
        right: 20px;
      }
    }

    &__store {
      @include system.text-bold();
    }

    &__name {
      margin: 0 0 0 20px;
      @include system.text-bold();
    }

    &__status {
      margin: 0 0 0 20px;
      color: #DA756F;
    }
  }

  &__nav {
    width: 100%;
    max-width: 1216px;
    margin: 50px auto 0;
    padding: 0 8px;

    ul {
      display: flex;
      justify-content: space-between;

      &.sp-only {
        display: none;
      }
    }

    li {
      width: calc((100% - 60px) / 3);
      max-width: 380px;
    }

    @mixin icon($icon) {
      margin: 0 10px 0 0;
      @include system.fontAwesome($icon);

      @include system.breakpoint(tablet) {
        margin: 0;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px;
      background-color: system.$c-brown;
      border-radius: 30px;
      @include system.text-bold();
      color: system.$c-white;
      cursor: pointer;
    }

    li:nth-of-type(1) {
      a::before {
        @include icon('\f086');
        font-size: 24px;
      }
    }

    li:nth-of-type(2) {
      a::before {
        @include icon('\f4fe');
        font-size: 26px;
      }
    }

    li:nth-of-type(3) {
      span {
        display: inline-block;
        white-space: pre-line;
        text-align: left;
      }

      a::before {
        @include icon('\f484');
        font-size: 24px;
      }
    }
  }

  @include system.breakpoint(tablet) {
    margin: 0 0 80px;

    &__img {
      height: 200px;
    }

    &__nav {
      max-width: 520px;

      ul {
        &.pc-only {
          display: none;
        }

        &.sp-only {
          display: flex;
        }
      }

      li {
        position: relative;
        width: auto;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 160px;
        height: 95px;
        padding: 10px 0;
        border-radius: 10px;
        @include system.text-bold(16px);

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border: 2px solid system.$c-white;
          border-radius: 10px;
          content: '';
        }
      }

      span {
        margin: 3px 0 0;
        white-space: pre-line;
        text-align: center;
      }

      li:nth-of-type(1) {
        a::before {
          font-size: 27px;
        }
      }

      li:nth-of-type(2) {
        a::before {
          font-size: 30px;
        }

        span {
          margin: 8px 0 0;
        }
      }

      li:nth-of-type(3) {
        a::before {
          font-size: 28px;
        }
      }
    }
  }

  @include system.breakpoint(sp) {
    &__img {
      height: 120px;
    }

    &__user {
      height: auto;

      p {
        flex-direction: column;
        padding: 15px 0;
      }

      &__name {
        margin: 7px 0 0;
      }

      &__status {
        margin: 20px 0 0;
        font-size: 12px;
      }
    }

    &__nav {
      max-width: 380px;
      margin: 35px auto 0;

      a {
        max-width: 120px;
        height: 85px;
        @include system.text-bold(12px);
      }

      li:nth-of-type(1),
      li:nth-of-type(3) {
        a::before {
          font-size: 24px;
        }
      }

      li:nth-of-type(2) {
        a::before {
          font-size: 26px;
        }

        span {
          margin: 5px 0 0;
        }
      }
    }
  }
}