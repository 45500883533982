@use "../../foundation/system" as system;

.p-reserve {
  &__form {
    position: relative;
    width: 100%;
    padding: 50px 24px 40px 26px;
    margin: 0 0 50px;
    @include system.formBackground();

    button {
      margin: 70px auto 0;
    }

    &__select {
      margin: 0 0 50px;
    }
  }

  &__lineButton {
    display: flex;
    justify-content: center;
    //margin: 0 0 100px;

    button,
    a {
      width: 100%;
      max-width: 600px;
      padding: 20px 0;
      text-align: center;
      background-color: system.$c-green;
      @include system.text-bold(20px);
      color: system.$c-white;
    }
  }

  &__list {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1440px;
      margin: 85px auto 0;
      padding: 0 60px 10px;
    }
  }

  &__prescription {
    width: 100%;
  }

  &__attention {
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: 250px auto 0;
    padding: 50px 35px 40px;
    border: 2px dashed system.$c-red;

    p {
      @include system.title-base();
      text-align: center;
    }
  }

  &__userInfo {
    margin: 210px 0 0;
  }

  &__template {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    background-color: rgba(242, 255, 244, 0.75);
  }

  @include system.breakpoint(tablet) {
    &__form {
      button {
        margin: 45px auto 0;
      }

      &__select {
        margin: 0 0 35px;
      }
    }

    &__lineButton {
      margin: 0 0 35px;

      button,
      a {
        padding: 10px 0;
      }
    }

    &__list {
      padding: 0;

      ul {
        margin: 50px 0 0;
        padding: 0;
      }
    }

    &__display {
      margin: 65px 0 0;
      padding: 0 0 0 22px;
    }

    &__attention {
      margin: 100px auto 60px;

      p {
        text-align: left;
      }
    }

    &__userInfo {
      margin: 45px 0 0;
    }
  }

  @include system.breakpoint(sp) {
    &__lineButton {
      button,
      a {
        max-width: 300px;
        font-size: 11px;
      }
    }

    &__attention {
      p {
        font-size: 11px;
      }
    }
  }
}
