@use '../foundation/system' as system;

.l-message {
  margin: 150px 0 120px;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    @include system.text-bold(20px);

    &::before {
      margin: 0 10px 0 0;
      @include system.fontAwesome('\f0e0');
      font-size: 26px;
    }
  }

  ul {
    margin: 60px 0 0;

    & + ul {
      margin: 20px 0 0;
    }
  }

  li {
    width: 100%;
    max-width: 890px;
    margin: 0 0 0 auto;
    padding: 25px 20px 30px;
    background-color: #f4f4f4;

    &.user {
      background-color: system.$c-lightGreen;
      margin: 0 auto 0 0;

      .l-message__message {
        box-shadow: 1px 1px 2px rgba(34, 34, 34, 0.15);
      }
    }

    & + li {
      margin-top: 20px;
    }
  }

  &__message {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 10px;
    background-color: system.$c-white;
  }

  &__date {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    @include system.text-base(20px);

    span {
      display: inline-block;
      margin: 0 0 0 5px;
    }

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome('\f017');
      font-weight: 400;
    }
  }

  &__text {
    width: 100%;
    max-width: 540px;
    margin: 25px auto 0;
    @include system.text-base(16px);
    line-height: 1.5;
  }

  .new {
    display: flex;
    justify-content: center;
    padding: 0 20px;

    li {
      width: 100%;
      max-width: 1200px;
      margin: 0;
    }
  }

  button {
    margin: 50px auto 0;
  }

  @include system.breakpoint(tablet) {
    margin: 80px 0 60px;

    ul {
      margin: 30px 0 0;
    }

    li {
      padding: 15px 20px;
    }

    &__message {
      padding: 13px 25px 20px;
    }

    &__text {
      margin: 15px auto 0;
    }

    .new {
      padding: 0;
    }
  }

  @include system.breakpoint(sp) {
    h3 {
      font-size: 14px;
    }

    &__date {
      font-size: 14px;
    }

    &__text {
      font-size: 11px;
    }

    button {
      margin: 20px auto 0;
    }
  }
}