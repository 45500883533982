@use '../../foundation/system' as system;

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.c-actionButton {
  display: flex;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 80px;
    background-color: system.$c-blue;
    border-radius: 10px;
    @include system.text-bold(24px);
    color: system.$c-white;
    white-space: pre-wrap;

    &::after {
      margin: 0 0 0 50px;
      @include system.fontAwesome('\f35a');
      font-weight: 400;
    }
  }

  @include system.breakpoint(tablet) {
    justify-content: flex-start;

    button {
      justify-content: flex-start;
      width: 330px;
      height: 60px;
      padding: 0 0 0 30px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 16px;

      &::after {
        margin: 0 0 0 40px;
      }
    }
  }

  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fffa;
    z-index: 100;
    > span {
      width: 20px;
      height: 20px;
      border: 2px solid system.$c-blue;
      border-right-color: transparent;
      border-radius: 50%;
      animation: rotate 0.5s infinite linear;
      &::after {
        display: none;
      }
    }
  }

  @include system.breakpoint(sp) {
    button {
      font-size: 14px;
    }
  }
}
