@use '../../foundation/system' as system;

.c-newsList {
  width: 100%;
  padding: 0 0 30px;

  & + li {
    margin: 50px 0 0;
  }

  a {
    position: relative;
    display: block;
    padding: 0 0 30px;

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 290px;
      height: 9px;
      background: no-repeat url(/assets/img/listArrow.svg);
      background-size: 100%;
      content: '';
    }
  }

  &__date {
    &::before {
      margin: 0 8px 0 0;
      @include system.fontAwesome('\f017');
      font-weight: 400;
    }

    span {
      margin: 0 0 0 5px;
    }
  }

  &__title {
    margin: 20px 0 0;
    @include system.text-bold(22px);
    color: system.$c-blue;
  }

  @include system.breakpoint(tablet) {
    padding: 0 12px 15px 25px;

    a::after {
      bottom: 10px;
      right: 13px;
      width: 180px;
      height: 6px;
    }

    & + li {
      margin: 30px 0 0;
    }

    &__title {
      margin: 10px 0 0;
    }
  }

  @include system.breakpoint(sp) {
    &__date {
      font-size: 10px;
    }

    &__title {
      font-size: 12px;
    }
  }
}