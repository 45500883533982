@use '../../foundation/system' as system;

.p-thanks {
  width: calc(100% - 120px);
  margin: 0 auto;

  h2 {
    @include system.text-bold(20px);
    text-align: center;

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome('\f00c');
      font-size: 24px;
      color: system.$c-brown;
    }
  }

  div {
    width: 80%;
    margin: 56px auto 66px;
    padding: 20px 20px 40px;
    border: 2px dashed #B8B8B8;
  }

  &__bold {
    margin: 0 0 30px;
    @include system.text-bold();
  }

  p {
    margin: 20px 0 0;
  }

  @include system.breakpoint(tablet) {
    width: calc(100% - 40px);

    h2 {
      &::before {
        font-size: 20px;
      }
    }

    div {
      width: 100%;
    }
  }
}