@use '../../foundation/system' as system;

.p-signIn {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 50px 24px 30px 26px;
  margin: 0 0 50px;
  @include system.formBackground();

  &__error {
    display: block;
    width: 100;
    max-width: 900px;
    margin: 0 auto 30px;
    @include system.text-base(16px);
    color: system.$c-red;
    line-height: 1.5;
  }

  h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    @include system.text-bold(24px);
    white-space: nowrap;

    &::before {
      margin: 0 13px 0 0;
      @include system.fontAwesome("\f2f6");
    }
  }

  form {
    button {
      margin: 60px auto 0;
    }
  }

  > div {
    max-width: 95%;
    margin: 50px 0 0;
  }

  @include system.breakpoint(tablet) {
    h2 {
      font-size: 20px;
    }

    form {
      button {
        margin: 30px auto 0;
      }
    }
  }

  @include system.breakpoint(sp) {
    h2 {
      font-size: 16px;
    }
  }
}