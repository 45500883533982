@use '../foundation/system' as system;

.l-footer {
  position: relative;
  width: 100%;
  margin: 200px auto 0;
  padding: 30px 20px 20px 0;
  background-color: system.$c-black;

  &__btn {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 72px;
    background-color: system.$c-white;
    border-radius: 10px;
    cursor: pointer;

    &::before {
      font-size: 50px;
      @include system.fontAwesome("\f139");
      color: system.$c-black;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 202px;
    }
  }

  &__signOut {
    margin: 10px 0 0;
    text-align: center;
  }

  nav {
    margin: 30px 0 0;
  }

  &__list {
    display: flex;
    justify-content: center;

    li {
      padding: 0 10px;
      text-align: center;

      & + li {
        border-left: 1px solid system.$c-white;
      }
    }

    & + ul {
      margin: 20px 0 0;
    }

    a {
      color: system.$c-white;
    }
  }

  small {
    display: block;
    margin: 50px 0 0;
    @include system.text-base();
    color: system.$c-white;
    text-align: center;
  }

  @include system.breakpoint(tablet) {
    margin: 80px auto 0;
    padding: 25px 20px;

    &__btn {
      width: 80px;
      height: 50px;

      &::before {
        font-size: 40px;
      }
    }

    &__logo {
      flex-direction: row;
    }

    &__signOut {
      margin: 0 0 0 15px;
      text-align: left;
    }

    &__list {
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 400px;
      margin: 0 0 0 -10px;

      li:nth-of-type(3) {
        margin: 15px 0 0;
        border-left: unset;
        text-align: left;
      }

      & + ul {
        margin: 15px 0 0 -10px;
      }
    }

    small {
      margin: 40px 0 0;
      text-align: right;
    }
  }

  @include system.breakpoint(sp) {
    padding: 25px 0 25px 20px;

    &__logo {
      img {
        width: 127px;
      }
    }

    &__signOut {
      font-size: 12px;
    }
  }
}